<template>
  <div>
    <b-col cols="12">
      <b-card class="p-1">
        <b-row class="w-100 m-0 p-0">
          <b-col
            col
            cols="12"
            xl="3"
            md="3"
            class=""
          >
            <b-button
              variant="primary"
              icon-pack="feather"
              icon="icon-arrow-left"
              :class="{'w-100': isMobile}"
              @click="$router.push({ name: 'platform-analytics' })"
            >Go Back</b-button>
          </b-col>
          <b-col
            col
            cols="12"
            xl="9"
            md="9"
            class="text-center mt-1 mt-xl-0 mt-md-0 w-100">
            <!-- <h3>Reports Name</h3> -->
            <h3 class="m-0 p-0">{{ $route.params.report_name.split('-')[0] }}</h3>
          </b-col>

          <b-col
            cols="3"
            class="text-right"
          >
            <!-- <b-button
              variant="warning"
              icon-pack="feather"
              icon="icon-edit-2"
              @click="isEditReport = !isEditReport"
            >Edit Report</b-button> -->
            <!-- <b-button
              variant="dark"
              icon-pack="feather"
              class="shadow-lg mx-1"
            >Download Report</b-button> -->
          </b-col>
        </b-row>
      </b-card>

      <b-tabs
        lazy
        pills
        justified
        align="center"
        class="tab-action-btn-fill-container"
      >
        <!-- <b-tab
          lazy
        >
          <template #title>
            <span class="">Pre Event</span>
          </template>

          <pre-event
            :is-edit-report="isEditReport"
            :report-data="reportData"
          />
        </b-tab> -->

        <!-- <b-tab lazy>
          <template #title>
            <span class="">During Event</span>
          </template>

          <during-event
            :is-edit-report="isEditReport"
            :report-data="reportData"
          />
        </b-tab> -->

        <!-- <b-tab lazy>
          <template #title>
            <span class="">Post Event</span>
          </template>

          <post-event :report-data="reportData" />
        </b-tab -->

        <b-tab lazy>
          <template #title>
            <span class="">Summary</span>
          </template>

          <platform-analytics-summary v-if="renderComponent" :report-data="reportData" />
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { onResizeMixins } from '@/components/mixins/onResizeMixins'

// tabs
import PreEvent from './components/PreEvent.vue'
import DuringEvent from './components/DuringEvent.vue'
import PostEvent from './components/PostEvent.vue'
import PlatformAnalyticsSummary from './components/PlatformAnalyticsSummary.vue'

export default {
  components: {
    // tabs
    PreEvent,
    DuringEvent,
    PostEvent,
    PlatformAnalyticsSummary,
  },
  mixins: [onResizeMixins],
  data() {
    return {
      renderComponent: true,
      isEditReport: false,
      isLoading: false,
      reportData: {
        statistics: {
          charts: {
            job_seeker: {},
            employer: {},
          },
        },
      },
    }
  },
  watch: {
  },
  created() {
    this.getData()
  },
  methods: {
    removeChart(uen) {
      this.charts = this.charts.filter(chart => {
        if (chart.uen !== uen) return chart
      })
    },

    getData() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true
      const eventId = this.$route.params.report_name.split('-')[1]

      this.$http
        .get(`/api/event-reports/${eventId}`)
        .then(response => {
          const output = response.data
          const data = output.reports

          this.$set(this.reportData.statistics.charts.job_seeker, 'gender', {
            Male: data.males_count,
            Female: data.females_count,
          })

          this.$set(this.reportData.statistics.charts.job_seeker, 'highest_qualification_count', data.highest_qualification_count)
          this.$set(this.reportData.statistics.charts.job_seeker, 'citizenship_count', {
            'Singaporean': data.citizenship_singaporean_count,
            'Singaporean PR': data.citizenship_singaporean_pr_count,
            'Other': data.citizenship_other_count,
          })

          this.$set(this.reportData.statistics, 'number_of_employers_account', data.total_employers)
          this.$set(this.reportData.statistics, 'number_of_users_account', data.total_applicants)

          this.$set(this.reportData.statistics.charts.job_seeker, 'employment_status', {
            employed: data.employment_status_employed_count,
            unemployed_for_less_than_6_months: data.employment_status_unemployed_for_less_than_6_months_count,
            unemployed_for_more_than_6_months: data.employment_status_unemployed_for_more_than_6_months_count,
          })


          this.$set(this.reportData.statistics.charts.job_seeker, 'users_activity_per_month', data.job_seeker_login_chart)
          this.$set(this.reportData.statistics.charts.job_seeker, 'job_applications_chart', data.job_applications_chart)

          this.$set(this.reportData.statistics.charts.employer, 'users_activity_per_month', data.employer_login_chart)
          this.$set(this.reportData.statistics.charts.employer, 'active_job_chart', data.active_job_chart)

          this.renderComponent = false
          setTimeout(() => {
            this.renderComponent = true
          }, 299);

          // this.reportData.statistics.charts.job_seeker = {
          // }
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Failed',
                icon: 'CheckCircleIcon',
                text: 'Could not retrieve the event reports. Please try again later.',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/variables.scss';

.back-button {
  background-color: $sidebar-dark-bg;
}
</style>
