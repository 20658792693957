<template>
  <b-card
    no-body
    :style="`height: ${componentOptions.cardHeight};`"
  >
    <b-card-header>
      <b-card-title>Gender</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-overlay :show="isLoading">
        <!-- apex chart -->
        <vue-apex-charts
          type="donut"
          height="220"
          class="my-1"
          :options="gender.chartOptions"
          :series="gender.series"
        />

        <!-- chart info -->
        <div
          v-for="(data, key, index) in chartInfo"
          :key="key"
          class="d-flex justify-content-between"
          :class="index === Object.keys(chartInfo).length - 1 ? '' : 'mb-1'"
        >
          <div class="series-info d-flex align-items-center">
            <feather-icon
              icon="CircleIcon"
              size="16"
              :style="`color: ${data.color}`"
            />
            <span class="font-weight-bold text-capitalize ml-75">{{
              data.title
            }}</span>
          </div>

          <span>{{ data.value }}</span>
        </div>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: ['componentOptions', 'reportData', 'section'],
  data() {
    return {
      isLoading: false,

      chartInfo: {
        male: {
          value: 0,
          title: 'Male',
          color: '#19a8e6',
        },
        female: {
          value: 0,
          title: 'Female',
          color: '#ff3030',
        },
      },
      gender: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Male', 'Female'],
          colors: ['#19a8e6', '#ff3030'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      },
    }
  },
  watch: {
    reportData(newVal, oldVal) {
      this.updateData(newVal)
    },
  },
  mounted() {
    if (Object.keys(this.reportData).length > 0) {
      this.updateData(this.reportData)
    }
  },
  methods: {
    updateData(newVal) {
      const chartData = newVal.statistics.charts[this.section].gender
      this.$set(this, 'chartInfo', {
        male: {
          value: chartData.Male,
          title: 'Male',
          color: '#19a8e6',
        },
        female: {
          value: chartData.Female,
          title: 'Female',
          color: '#ff3030',
        },
      })
      // this.chartInfo = {
      //   male: {
      //     value: chartData.Male,
      //     title: 'Male',
      //     color: '#19a8e6',
      //   },
      //   female: {
      //     value: chartData.Female,
      //     title: 'Female',
      //     color: '#ff3030',
      //   },
      // }
      this.gender = {
        series: [chartData.Male, chartData.Female],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Male', 'Female'],
          colors: ['#19a8e6', '#ff3030', $themeColors.dark],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      }
    },
  },
}
</script>
