var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(false)?_c('b-row',[_c('b-col',[_c('statistic-card-vertical',{attrs:{"color":"success","icon":"CheckIcon","statistic":_vm.reportData.statistics.Hired,"statistic-title":"Hired"}})],1),_c('b-col',[_c('statistic-card-vertical',{attrs:{"color":"warning","icon":"ListIcon","statistic":_vm.reportData.statistics.Shortlisted,"statistic-title":"Shortlisted "}})],1),_c('b-col',[_c('statistic-card-vertical',{attrs:{"color":"danger","icon":"XIcon","statistic":_vm.reportData.statistics.Rejected,"statistic-title":"Unsuccessful"}})],1),_c('b-col',[_c('statistic-card-vertical',{attrs:{"color":"warning","icon":"ClockIcon","statistic":_vm.reportData.statistics['Kept In View'],"statistic-title":"Pending"}})],1),_c('b-col',[_c('statistic-card-vertical',{attrs:{"color":"danger","icon":"AlertTriangleIcon","statistic":_vm.reportData.statistics.Unknown,"statistic-title":"Unknown"}})],1)],1):_vm._e(),_c('hr'),_c('h3',[_vm._v("Job Seeker")]),(false)?_c('b-row',[_c('b-col',[_c('statistic-card-horizontal',{attrs:{"icon":"BriefcaseIcon","statistic":_vm.reportData.statistics.total_jobs,"statistic-title":"Total Jobs"}})],1),_c('b-col',[_c('statistic-card-horizontal',{attrs:{"icon":"UserIcon","statistic":_vm.reportData.statistics.total_applicants,"statistic-title":"Total Applicants"}})],1),_c('b-col',[_c('statistic-card-horizontal',{attrs:{"icon":"CalendarIcon","statistic":_vm.reportData.statistics.total_applications,"statistic-title":"Scheduled Interview"}})],1),_c('b-col',[_c('statistic-card-horizontal',{attrs:{"variant":"success","icon":"CheckIcon","statistic":_vm.reportData.statistics.all_interviews_completed,"statistic-title":"Interview Completed"}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"3","md":"6"}},[_c('gender-chart',{attrs:{"component-options":{
          cardHeight: '60vh'
        },"report-data":_vm.reportData,"section":"job_seeker"}})],1),_c('b-col',{attrs:{"cols":"12","xl":"3","md":"6"}},[_c('employment-status-chart',{attrs:{"component-options":{
          cardHeight: '60vh'
        },"report-data":_vm.reportData,"section":"job_seeker"}})],1),_c('b-col',{attrs:{"cols":"12","xl":"3","md":"6"}},[_c('citizenship-chart',{attrs:{"component-options":{
          cardHeight: '60vh'
        },"report-data":_vm.reportData,"section":"job_seeker"}})],1),_c('b-col',{attrs:{"cols":"12","xl":"3","md":"6"}},[_c('qualification-level-list',{attrs:{"component-options":{
          cardHeight: '60vh'
        },"report-data":_vm.reportData,"section":"job_seeker"}})],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6"}},[_c('statistic-card-horizontal',{attrs:{"icon":"UserIcon","statistic":_vm.reportData.statistics.number_of_users_account,"statistic-title":"Number of Job Seekers Account"}}),_c('b-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","xl":"12","md":"12"}},[_c('number-of-applicant-chart',{attrs:{"component-options":{
            cardHeight: '41vh'
          },"report-data":_vm.reportData,"which-page":'summary',"title":"User Login Activity (Based on login time) - 30 days","section":"job_seeker"}})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6"}},[_c('b-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","xl":"12","md":"12"}},[_c('ChartComponent',{attrs:{"component-options":{
            cardHeight: '60vh'
          },"report-data":_vm.reportData,"which-page":'summary',"title":"Job Applications - 30 days","section":"job_seeker","data-name":"job_applications_chart"}})],1)],1)],1),_c('h3',[_vm._v("Employer")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6"}},[_c('statistic-card-horizontal',{attrs:{"icon":"UserIcon","statistic":_vm.reportData.statistics.number_of_employers_account,"statistic-title":"Number of Employers Account"}}),_c('b-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","xl":"12","md":"12"}},[_c('number-of-applicant-chart',{attrs:{"component-options":{
            cardHeight: '41vh'
          },"report-data":_vm.reportData,"which-page":'summary',"title":"User Login Activity (Based on login time) - 30 days","section":"employer"}})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6"}},[_c('b-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","xl":"12","md":"12"}},[_c('ChartComponent',{attrs:{"component-options":{
            cardHeight: '60vh'
          },"report-data":_vm.reportData,"which-page":'summary',"title":"Active Job - 30 days","section":"employer","data-name":"active_job_chart"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }